input::placeholder {
  @apply text-input-placeholder;
}



.btn.select-button {
  color: white !important;
  background: $dark-green;
  border-color: $stroke1;
  justify-content: flex-start;
  font-weight: 400;

  &.active {
    background: $stroke1;
  }
}

.formik-form {

  .form-field {

    input {
      width: 100%;
      @apply bg-input-bg border border-input-border text-input-text;
      outline: none;
      transition: all ease-in-out 0.2s;

      &::placeholder {
        @apply text-input-placeholder;
      }

      &:focus {
        @apply border-input-border-focus;
      }
    }

    &.is-invalid {
      label {
        @apply text-danger;
      }

      input {
        @apply border-danger;
      }

      .invalid-feedback {
        @apply block text-danger;
      }
    }

    &.rounded-xs input {
      border-radius: 4px;
    }

    &.rounded-sm input {
      border-radius: 0.5rem;
    }

    &.rounded-md input {
      border-radius: 1rem;

      @include breakpoint-down($xs) {
        border-radius: 5px;
      }
    }

    &.full-width {
      width: 100%;
    }

    &.size-md {
      input {
        font-size: 1rem;
        padding: 0.75rem 1rem;
      }

      .invalid-feedback {
        font-size: 0.75rem;
      }
    }

    &.size-lg {
      input {
        font-size: 1.25rem;
        padding: 1rem;
      }
    }

    &.size-xl {
      input {
        font-size: 1.5rem;
        padding: 1.6rem 2.75rem;

        @include breakpoint-down($xs) {
          font-size: 16px;
          padding: 14px 24px;
        }
      }
    }
  }
}

.radio-button {
  flex-grow: 0;
  flex-shrink: 0;
  width: 1.5rem;
  height: 1.5rem;
  border: 1px solid $blue;
  border-radius: 100px;
  padding: 3px;
  cursor: pointer;

  &.checked::after {
    display: block;
    content: '';
    width: 100%;
    height: 100%;
    border-radius: 100px;
    background: $blue;
  }

  &.size-xl {
    width: 2.75rem;
    height: 2.75rem;
    padding: 6px;
  }
}

.gradient-range-slider {
  width: 100%;
  display: block;
  -webkit-appearance: none;
  appearance: none;
  background: transparent;

  &::-webkit-slider-runnable-track {
    height: 4px;
    background: linear-gradient(270deg, #FF3C5F 12.03%, #FFB552 38.33%, #D7FF39 60.73%, #80FF50 74.27%, #00FF75 94.3%);
    border: none;
    border-radius: 4px;
  }
  &::-moz-range-track {
    height: 4px;
    background: linear-gradient(270deg, #FF3C5F 12.03%, #FFB552 38.33%, #D7FF39 60.73%, #80FF50 74.27%, #00FF75 94.3%);
    border: none;
    border-radius: 4px;
  }

  &::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    width: 10px;
    height: 10px;
    background: white;
    border: none;
    border-radius: 10px;
    margin-top: -3px;
  }

  &::-moz-range-thumb {
    -webkit-appearance: none;
    appearance: none;
    width: 10px;
    height: 10px;
    background: white;
    border: none;
    border-radius: 10px;
    margin-top: -3px;
  }
}
