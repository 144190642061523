@import "mixins";

$blue: #23B3D6;
$dark-blue: #009FCC;
$card1: #041D24;
$card2: #073F4F;
$stroke1: #03617B;
$stroke2: #3798B4;
$secondary: #97B7BE;
$danger: #FF3C5F;


$sky: #69CAE6;
$dark-sky: #27A6C9;
$light-green:#02C0C2;
$dark-green: #193E4E;
$body1: #062C37;
$primary: #3283FC;

$colors: (
    "blue": $blue,
    "dark-blue": $dark-blue,
    "stroke2": $stroke2,
    "sky": $sky,
    "dark-sky": $dark-sky,
    "light-green": $light-green,
    "green": $stroke1,
    "card2": $card2,
    "dark-green": $dark-green,
    "green-750": $body1,
    "card1": $card1,
    "primary": $primary,
    "secondary": $secondary,
    "danger": $danger,
    "unset": unset
);

$font-primary: Inter;
$font-title: Arial;

$xxl: 1512px;
$xl: 1280px;
$lg: 1024px;
$md: 768px;
$sm: 640px;
$xs: 500px;

$breakpoints: (
    "xxl": $xxl,
    "xl": $xl,
    "lg": $lg,
    "md": $md,
    "sm": $sm,
    "xs": $xs,
);
