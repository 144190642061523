:root {
  font-size: 12px;
  @apply text-[12px] sm:text-[13px] md:text-[14px] lg:text-[15px] xl:text-[16px];
}

body {
  @apply bg-body1 text-typo1 font-primary m-0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

::-webkit-scrollbar-thumb {
  background-color: darkgrey;
  border-radius: 8px;
}

@layer utilities {
  @variants responsive {
    .flex-center {
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .card1 {
      @apply bg-card1 border-card border-stroke1 rounded-10p overflow-hidden;
    }

    .card2 {
      @apply bg-card2 border-card border-stroke2 rounded-10p overflow-hidden;
    }

    .card3 {
      @apply bg-card3 text-card3-text rounded-10p overflow-hidden;
    }

    .card4 {
      @apply bg-card4 border-card text-card4-text border-card4-border rounded-10p overflow-hidden;
    }

    .card-outline1 {
      @apply bg-transparent border border-card-outline1 rounded-10p overflow-hidden;
    }

    .card-dark-cyan {
      display: block;
      background: #09344F;
      border: 1px solid #09344F;
      border-radius: 10px;
      overflow: hidden;
    }

    .gradient-transparent-body1 {
      @apply bg-gradient-to-b from-transparent from-90% to-body1;
    }

    .rotate-y-180 {
      transform: rotateY(180deg);
    }
  }
}
