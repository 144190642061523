@mixin breakpoint($size) {
  @media (min-width: $size) {
    @content;
  }
}

@mixin breakpoint-down($size) {
  @media (max-width: ($size - 1px)) {
    @content;
  }
}