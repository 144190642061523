.CircularProgressbar {

  .CircularProgressbar-trail {
    @apply stroke-progress-trail-circular;
  }

  .CircularProgressbar-path {
    @apply stroke-progress-path;
  }

  .CircularProgressbar-text {
    @apply fill-typo1 font-bold;
  }
}

.swiper {

  &.hide-navigation {

    .swiper-button-prev, .swiper-button-next {
      display: none;
    }
  }
}
